var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"right-sidebar\" id=\"right-sidebar\" role=\"navigation\">\n    <div class=\"right-sidebar-items\">\n        <div id=\"user-list\">\n            <div id=\"userlist-header\">\n                <div id=\"userlist-header-search\">\n                    <input class=\"user-list-filter home-page-input filter_text_input\" type=\"text\" autocomplete=\"off\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":130},"end":{"line":6,"column":151}}}))
    + "\" />\n                    <button type=\"button\" class=\"bootstrap-btn hidden\" id=\"clear_search_people_button\">\n                        <i class=\"zulip-icon zulip-icon-close\" aria-hidden=\"true\"></i>\n                    </button>\n                </div>\n                <span id=\"buddy-list-menu-icon\" class=\"user-list-sidebar-menu-icon\">\n                    <i class=\"zulip-icon zulip-icon-more-vertical\" aria-hidden=\"true\"></i>\n                </span>\n            </div>\n            <div id=\"buddy_list_wrapper\" class=\"scrolling_list\" data-simplebar data-simplebar-tab-index=\"-1\">\n                <div id=\"buddy-list-participants-container\" class=\"buddy-list-section-container\">\n                    <div class=\"buddy-list-subsection-header\"></div>\n                    <ul id=\"buddy-list-participants\" class=\"buddy-list-section\"></ul>\n                </div>\n                <div id=\"buddy-list-users-matching-view-container\" class=\"buddy-list-section-container\">\n                    <div class=\"buddy-list-subsection-header\"></div>\n                    <ul id=\"buddy-list-users-matching-view\" class=\"buddy-list-section\"></ul>\n                </div>\n                <div id=\"buddy-list-other-users-container\" class=\"buddy-list-section-container\">\n                    <div class=\"buddy-list-subsection-header\"></div>\n                    <ul id=\"buddy-list-other-users\" class=\"buddy-list-section\"></ul>\n                </div>\n                <div id=\"buddy_list_wrapper_padding\"></div>\n                <div class=\"invite-user-shortcut\">\n                    <a class=\"invite-user-link\" role=\"button\">\n                        <i class=\"zulip-icon zulip-icon-user-plus\" aria-hidden=\"true\"></i>\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invite users to organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":24},"end":{"line":32,"column":61}}}))
    + "\n                    </a>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});