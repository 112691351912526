var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":4,"column":14},"end":{"line":4,"column":18}} ), depth0))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "            <a class=\"group_list_item_link\" href=\""
    + alias3(alias2(alias1(depth0, "group_edit_url", {"start":{"line":6,"column":52},"end":{"line":6,"column":66}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":6,"column":72},"end":{"line":6,"column":76}} ), depth0))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-group-id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "group_id", {"start":{"line":1,"column":21},"end":{"line":1,"column":29}} ), depth0))
    + "\">\n    <td class=\"group_list_item\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_guest"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n</tr>\n";
},"useData":true});